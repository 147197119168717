<template>
  <v-app>
       <v-main>
    <v-container>
      <v-row>
        <v-col>
          <v-card class="pt-10" elevation="0">
            <v-card-text class="text-center">
              <v-img
                :src="require('@/assets/Text-Color.svg')"
                contain
                width="163"
                class="ma-auto"
              ></v-img>

              <div class="pt-2">
                <h4 class="text-h5 primary--text">{{ $t("signin.title") }}</h4>
              </div>
              ซอฟแวร์ทำงานอยู่บน {{browser}} กรุณาติดตั้งตามคำแนะนำ
            </v-card-text>
            <v-card-actions>
            <v-btn large
                block
                class="text-subtitle-1" :to="{name:'home'}" color="primary">ข้าม</v-btn>
           
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-bottom-sheet
      v-model="sheet"
    >
     <v-sheet
        class="text-center"
        height="100px"
      >
        <div class="py-3 primary--text text--darken-4">
        2. เลือกเพิ่มไปยังหน้าจอโฮม <v-icon color="primary darken-4">mdi-plus-box-outline</v-icon>
        </div>
        <div class=" primary--text text--darken-4">
            1. คลิกที่ปุ่มเมนูนี้
        </div>
        <div class=" primary--text text--darken-4">
            
            <v-icon color="primary darken-4">mdi-arrow-down-bold</v-icon>
        </div>
      </v-sheet>
    </v-bottom-sheet>
  </v-main>

     
  </v-app>
</template>

<script>
const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test( userAgent );
}

export default {
    data() {
        return {
            sheet: true
        }
    },
    computed: {
        browser() {
            let displayMode = 'browser';
              const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }
            return displayMode
        }
    },
 methods: {
     fetchData() {
         console.log('Check Operation mode')
         let displayMode = 'browser';
              const mqStandAlone = '(display-mode: standalone)';
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    displayMode = 'standalone';
  }
        if(displayMode === 'standalone' || !isIos() ) {
           
           this.$router.push({name:'home'})
        }
     },
     installApp(){
     // Show the install prompt
  this.deferredPrompt.prompt();
  // Wait for the user to respond to the prompt
  this.deferredPrompt.userChoice.then((choiceResult) => {
    if (choiceResult.outcome === 'accepted') {
      console.log('User accepted the install prompt');
    } else {
      console.log('User dismissed the install prompt');
    }
  });
 },
 },
 created () {
     this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    );
  }
}
</script>

<style lang="scss" scoped>
.v-btn {
  border-radius: 6px;
}
</style>